import * as React from 'react';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

export const EsgTab =({esgScore}) => {
    const settings = {
        width: 100,
        height: 100,
        startAngle: -110,
        endAngle: 110
    };
    const envScore = esgScore.environment
    const totalScore = esgScore.total
    const socialScore = esgScore.social
    const governanceScore = esgScore.governance
    return(
        <div className="mt-4 w-100 bg-backgroundbody-background-color rounded border border-solid border-controlsdivider-color20 shadow-shadow-base text-left">
          <div className="w-96 ml-4 mt-4 mb-4 flex">
            <div>
                <div className="text-center">Total ESG Score</div>
                <Gauge
                    value={totalScore}
                    width={400}
                    height={200}
                    startAngle={-110}
                    endAngle={110}
                    cornerRadius={"50%"}
                    sx={(theme) => ({
                        [`& .${gaugeClasses.valueText}`]: {
                        fontSize: 40,
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                        fill: '#52b202',
                        },
                        [`& .${gaugeClasses.referenceArc}`]: {
                        fill: theme.palette.text.disabled,
                        },
                    })}
                    text={
                        ({ value }) => `${totalScore} `
                    }
                />
                <div>Peer group ESG scores:</div>
                <div className="flex justify-between pr-12"><span>max:</span> <span className="font-bold">{esgScore.peer_esg_score_performance_min}</span></div>
                <div className="flex justify-between pr-12"><span>min:</span> <span className="font-bold">{esgScore.peer_esg_score_performance_max}</span></div>
                <div className="flex justify-between pr-12"><span>avg:</span> <span className="font-bold">{esgScore.peer_esg_score_performance_avg}</span></div>
            </div>
            <div>
                <div className="flex">
                    <div className="flex">
                    <Gauge
                        value={envScore}
                        {...settings}
                        cornerRadius="50%"
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 24,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                            fill: '#52b202',
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                            fill: theme.palette.text.disabled,
                            },
                        })}
                        text={
                            ({ value }) => `${envScore} `
                        }
                    />
                    </div>
                    <div className="grid place-items-center ml-8">Environmental</div>
                </div>
                <div className="flex">
                    <div className="flex">
                        <Gauge
                            value={socialScore}
                            {...settings}
                            cornerRadius="50%"
                            sx={(theme) => ({
                                [`& .${gaugeClasses.valueText}`]: {
                                fontSize: 24,
                                },
                                [`& .${gaugeClasses.valueArc}`]: {
                                fill: '#1d4ed8',
                                },
                                [`& .${gaugeClasses.referenceArc}`]: {
                                fill: theme.palette.text.disabled,
                                },
                            })}
                            text={
                                ({ value }) => `${socialScore} `
                            }
                        />
                    </div>
                    <div className="grid place-items-center ml-8">Social</div>
                </div>
               <div className="flex">
                    <div className="flex">
                    <Gauge
                        value={governanceScore}
                        {...settings}
                        cornerRadius="50%"
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 24,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                            fill: '#eab308',
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                            fill: theme.palette.text.disabled,
                            },
                        })}
                        text={
                            ({ value }) => `${governanceScore} `
                        }
                    />
                    </div>
                    <div className="grid place-items-center ml-8">Governance</div>
                </div>
            </div>  
          </div>
        </div>
)
}
