import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

export const OverviewTab =({esgScore, riskScore, financialScore}) => {
    const settings = {
        width: 300,
        height: 300,
        startAngle: -110,
        endAngle: 110
    };
    return(
        <div className="mt-4 w-100 bg-backgroundbody-background-color rounded border border-solid border-controlsdivider-color20 shadow-shadow-base text-left">
            <div className="w-96 ml-4 mt-4 mb-4 flex">
                <div>
                    <div className="text-center">ESG Score</div>
                    <Gauge
                        {...settings}
                        value={esgScore}
                        cornerRadius={"50%"}
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 40,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                            fill: '#52b202',
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                            fill: theme.palette.text.disabled,
                            },
                        })}
                        text={
                            ({ value }) => `${esgScore} `
                        }
                    />
                </div>
                <div>
                    <div className="text-center">Financial Score</div>
                    <Gauge
                        {...settings}
                        value={financialScore}
                        cornerRadius={"50%"}
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 40,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                            fill: '#1d4ed8',
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                            fill: theme.palette.text.disabled,
                            },
                        })}
                        text={
                            ({ value }) => `${financialScore} `
                        }
                    />
                </div>
                <div>
                    <div className="text-center">Risk Score</div>
                    <Gauge
                        {...settings}
                        value={riskScore}
                        cornerRadius={"50%"}
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 40,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                            fill: '#eab308',
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                            fill: theme.palette.text.disabled,
                            },
                        })}
                        text={
                            ({ value }) => `${riskScore} `
                        }
                    />
                </div>
            </div>
        </div>
    )
}