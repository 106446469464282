import * as React from 'react';
import {useNavigate} from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {getList} from '../../common/BackendProvider';
import {toast} from "react-toastify";

export const Search = () => {
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);
    const [loading, setLoading] = React.useState(true)
    const autocompleteService = { current: null };
    const navigate = useNavigate();

    React.useEffect(() => {
      
      let active = true;
      if (inputValue === '') {
        setOptions(value ? [value] : []);
        return undefined;
      }
      getList("companies-search/"+inputValue, {pagination:{page:1, perPage:6}}).then((response) => {
            setOptions(response.data.companies)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading companies", {})
                setLoading(false)
            }
        )

      return () => {
        active = false;
      };
    }, [value, inputValue, fetch]);
    return (
        <div className="flex flex-row items-center">
          <div className="rounded-[4px_0px_0px_4px] border-none">
            <Autocomplete
              id="company-search"
              sx={{ width: 600}}
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.name
              }
              filterOptions={(x) => x}
              options={options}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              noOptionsText="No matches"
              onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                if (newValue) {
                  navigate(`/company/${newValue.symbol}`)
                }
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Search name or symbol" fullWidth />
              )}
              renderOption={(props, option) => {
                const parts = [{ name: option.name, symol: option.symbol, highlight: false }];

                return (
                  <li {...props} key={option.id}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ display: 'flex', width: 44 }}>
                        <LocationOnIcon sx={{ color: 'text.secondary' }} />
                      </Grid>
                      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                        {parts.map((part, index) => (
                          <Box
                            key={index}
                            component="span"
                            sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                          >
                            {part.symol}
                          </Box>
                        ))}
                        <Typography variant="body2" color="text.secondary">
                          {/* {option.structured_formatting.secondary_text} */}
                          {option.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </div>
          
        </div>
    )
}
