import * as React from 'react';
import VerticalBarsChart from '../../components/Chart';
import Panel from '../../../layout/Panel';
import Title from '../../../layout/Title';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';


const Tile = ({title, description, value, icon, industryAverage, sectorAverage}) => {
    return(
        <div className="p-4 flex-1 bg-white text-gray-600 rounded border border-solid shadow-shadow-base text-left">
            <div className="flex">
                <div className="p-4 bg-primary-30 rounded-full">{icon}</div>
                <div className="ml-2">
                    <div className="p-2 text-xl font-bold">{title}: {value}</div>
                    <p className="pl-2 text-sm text-gray-600">{description}</p>
                </div>
            </div>
            <p className="mt-4">Industry Average: {industryAverage}</p>
            <p className="">Sector Average: {sectorAverage}</p>
        </div>
    )
}

export const FinanceTab =({company, stockData}) => {

    const indicators = [
        {
            "title": "P/E",
            "description": "Price over earnings",
            "value": "$ 123.34",
            "sectorAverage": "$ 105.34",
            "industryAverage": "$ 98.34",
            "icon": <TrendingUpIcon style={{ fontSize: 40, color: "#1E5EFF" }}/>
        },
        {
            "title": "PV",
            "description": "Present value",
            "value": "$ 34.35",
            "sectorAverage": "$ 45.34",
            "industryAverage": "$ 56.34",
            "icon": <MonetizationOnIcon style={{ fontSize: 40, color: "#1E5EFF" }}/>
        }]

    return(
        <>
            <div className="flex gap-4">
            <Panel>
                <Title>Stock Price</Title>
                <div className="pt-4 w-full">
                    <VerticalBarsChart
                        title={`↑ ${company.name} (${company.symbol}) stock price ($)`} 
                        data={stockData}
                    />
                </div>
            </Panel>
            </div>
            <div className="flex gap-4 mt-4">
                {indicators.map((indicator, index) => {
                    return <Tile 
                        key={index} 
                        title={indicator.title} 
                        description={indicator.description}
                        value={indicator.value} 
                        icon={indicator.icon}
                        industryAverage={indicator.industryAverage}
                        sectorAverage={indicator.sectorAverage}
                        />
                })}
            </div>
        </>
    )
}