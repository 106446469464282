import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { getOne } from '../../common/BackendProvider';
import { toast } from 'react-toastify';
import Tabs from '../../layout/Tabs';
  
export const NewsItem =() => {
    const[news, setNews] = useState([])
    const[esgKeyphrases, setEsgKeyphrases] = useState([])
    const[sentimentKeyphrases, setSentimentKeyphrases] = useState([])
    const[loading, setLoading] = useState(true)
    const[tab, setTab] = useState(0)
    const tabs = ["Content", "Processed Content", "Analysis"]
    let { newsID } = useParams(); 
    useEffect(() => {
        setLoading(true)
        getOne("news", {id: newsID}).then((response) => {
            setNews(response.data.news)
            setEsgKeyphrases(response.data.esgkeyphrases)
            setSentimentKeyphrases(response.data.sentimentkeyphrases)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading news", {})
                setLoading(false)
            }
        )
    },[])

    const handleTabs = (id) => {
        setTab(id)
    }

    return(
        <div className="relative top-16 mb-2 rounded border-red-800 font-black">
            <div className="grid text-left items-start top-0 ml-4 mr-4 left-2 w-100 bg-backgroundbody-background-color rounded border border-solid border-controlsdivider-color20 shadow-shadow-base">
                <div className="font-semibold text-center pt-4">{news.headline}</div>
                <div className="font-normal text-center pt-4">{news.summary}</div>
                <div className="font-normal p-4">Symbols: {news.symbols}</div>
                <div className="font-normal p-4">Published at: {(new Date(news.published_at)).toLocaleString("en-GB")}</div>
                <div className="font-normal p-4">Original: <a href={news.url} target='_blank'>{news.url}</a></div>
                <Tabs tabs={tabs} onSelection={handleTabs} />        
                {tab === 0 &&
                    <div className="font-normal p-4" dangerouslySetInnerHTML={{__html: news.content}} ></div>
                }
                {tab === 1 &&
                    <div className="font-normal p-4" dangerouslySetInnerHTML={{__html: news.content_cleaned}} ></div>
                }
                {
                    tab === 2 &&
                    <div className='grid grid-cols-3'>
                    <div>
                        <div className="font-normal p-4">Companies:</div>
                        {news.companies && news.companies.map((company) => {
                            return(
                                <div key={company.id} className="ml-4">
                                    <span className="font-semibold pr-4">{company.name}</span>
                                    <span className="font-normal">{company.symbol}</span>
                                </div> )   
                        })}
                    </div>
                    <div>
                        <div className="font-normal p-4">Keyword relevance:</div>
                        {esgKeyphrases && esgKeyphrases.map((phrase) => {
                            return(
                                <div key={phrase.id} className="ml-4">
                                    <span className="font-semibold pr-4">{phrase.esg_keyphrase}</span>
                                    <span className="font-normal">{phrase.score}</span>
                                </div> )   
                        })}
                    </div>
                    <div>
                        <div className="font-normal p-4">Sentiment:</div>
                        {sentimentKeyphrases && sentimentKeyphrases.map((phrase) => {
                            return(
                                <div key={phrase.id} className="ml-4">
                                    <span className="font-semibold pr-4">{phrase.sentiment_keyphrase}</span>
                                    <span className="font-normal">{phrase.score}</span>
                                </div> )   
                        })}
                    </div>
                    </div>
                }
            </div>
        </div>
    )
}