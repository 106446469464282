import { useEffect, useState } from 'react';
import { getList } from "../common/BackendProvider.jsx";
import {toast, ToastContainer} from "react-toastify";
import { format } from "d3";
import {Link} from 'react-router-dom'
import Table from '../layout/Table.jsx';
import Button from '../layout/components/Button.jsx';

export const InvestmentProfiles = () => {
    const[investmentProfiles, setInvestmentProfiles] = useState([])
    const[loading, setLoading] = useState(true)
    const loadInvestmentProfiles = () => {
        setLoading(true)
        getList("investment-profiles", {pagination:{page:1, perPage:100}}).then((response) => {
            setInvestmentProfiles(response.data.profiles)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading investment profiles", {})
                setLoading(false)
            }
        )
    }

    useEffect(() => {
        loadInvestmentProfiles()
    },[])
    return (
        <div className="p-8 border-l">
            <div className="flex justify-between">
                <div className="text-3xl font-bold mb-8">Investment Profiles</div>
                <div className="flex items-center">
                    <Link to = "/investment_profiles/create">
                    <Button variant="primary">
                    + Add Investment Profile</Button></Link></div>
            </div>
            <div className="bg-white border rounded-lg">
                <div className="p-4">
                </div>
                <div className="grid">
                    <div className="px-4">
                        <Table
                            className="w-full"
                            columns={["Name", "Description"]}
                            keys={[
                                {name: 'name', type: 'text', format: (d) => (d), class: "text-left", link: "/investment_profiles/{id}"},
                                {name: 'description', type: 'text', format: (d) => (d), class: "text-left", link: "/investment_profiles/{id}"},
                        ]}
                            data={investmentProfiles}
                            readOnly={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}