import { Search } from "./../pages/components/Search"
import { TopRightDropdown } from "./TopRightDropdown"


export const TopMenu = ({username}) => {
  console.log(username)
    return (  
        <div className="w-full mt-0 h-20 flex items-center justify-center bg-white">
          <div className=" w-full flex justify-between">
            <div className="ml-6 text-left font-bold text-[#323743] text-[32px] tracking-[0] leading-[48px]">
              <span className="italic text-[#00AEEF]">i</span>VC
            </div>
            <div className="pl-16">
            <Search/>
            </div>
            <div className="h-[48px] top-[16px] w-[48px]">
              <div className="h-[48px] rounded-[4px] overflow-hidden">
                <div className="relative w-[28px] h-[29px] top-[9px] left-[10px]">
                  <img className="hidden w-[28px] h-[28px] top-px left-0" alt="Bell" src="/images/bell.svg" />
                  <div className="hidden w-[13px] h-[13px] top-0 left-[14px] bg-[#de3b40] rounded-[5.5px] border-2 border-solid border-white" />
                </div>
              </div>
            </div>
            <TopRightDropdown username={username}/>
            </div>
        </div>
    )
}