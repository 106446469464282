import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import {getOne, create} from '../../common/BackendProvider';
import Tabs from '../../layout/Tabs';
import Panel from '../../layout/Panel';
import CircularPlot from '../components/CircularPlot';
import { StarInactive, StarActive } from '../../layout/images/Stars';
import { Link } from "react-router-dom";
import { EsgTab } from './tabs/EsgTab';
import { RiskTab } from './tabs/RiskTab';
import { FinanceTab } from './tabs/FinanceTab';
import { OverviewTab } from './tabs/OverviewTab';

const tabs = ["Summary", "ESG", "Financial", "Risk", "Description", "News Atricles"]
export const CompanyPage =() => {
    const[company, setCompany] = useState({})
    const[esgScore, setEsgScore] = useState({})
    const[stockData, setStockData] = useState([{}])
    let location = useLocation()
    const[loading, setLoading] = useState(true)
    const[isFavorite, setIsFavorite] = useState(false)
    const[tab, setTab] = useState(0)
    let { symbol } = useParams(); 
    useEffect(() => {
        setLoading(true)
        getOne("companies", {id: symbol}).then((response) => {
            setCompany(response.data.company)
            setEsgScore(response.data.esg_score)
            setStockData(response.data.stock_data)
            setIsFavorite((response.data.company.favorite.length)>0 ? true : false)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading companies", {})
                setLoading(false)
            }
        )
    },[location])
    
    const handleToggleFavorite = () => {
        create("watchlist", {data: {symbol: symbol}}).then((response) => {
            toast.success(response.data.message, {})
            setIsFavorite(!isFavorite)
        }).catch(
            (e) => {
                toast.error("Error while adding company to favorites", {})
            }
        )
    }

    if (company === null) {
        return <div>Company not found</div>
    }

    return (
      <Panel>
        <ToastContainer/>
        <div className="flex justify-between">
          <h1 className="text-left px-4 font-bold text-xl">{company.name} ({company.symbol})</h1>
          <div className="cursor-pointer mt-4" onClick={handleToggleFavorite}>{isFavorite ? <StarActive/> : <StarInactive/>}</div>
        </div>
        <Tabs tabs={tabs} onSelection={setTab}/>
        {tab === 0 &&
         <OverviewTab esgScore={esgScore.total} financialScore={company.financial_score} riskScore={company.risk_score}/>
        }
        {tab === 1 &&
        <EsgTab esgScore={esgScore}/>
        }
        {tab === 2 &&
        <FinanceTab company={company} stockData={stockData}/>
        }
        {tab === 3 &&
        <RiskTab risk1={company.risk_1} risk2={company.risk_2} risk3={company.risk_3}/>
        }
         {tab === 4 &&
        <div className="mt-4 w-100 bg-backgroundbody-background-color rounded border border-solid border-controlsdivider-color20 shadow-shadow-base text-left">
          <div className="font-normal p-2" ><span className="font-semibold">Sector:</span> {company.sector?.name}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Industry:</span> {company.industry?.name}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Region:</span> {company.region}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Country:</span> {company.country?.name}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Market Cap:</span> {company.market_cap}</div>
          <div className="font-normal p-2" ><span className="font-semibold">CEO:</span> {company.ceo}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Number of Employees:</span> {company.employees}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Website:</span>{company.website}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Description:</span> {company.description}</div>
        </div>
        }
        {tab === 5 &&
        <div>
          <ul>
            {company.news && company.news.map((news) => {
              return(
                <li key={news.id}>
                  <div className="mt-2 w-100 py-2 px-4 bg-backgroundbody-background-color rounded border border-solid border-controlsdivider-color20 shadow-shadow-base text-left">
                  <Link to = {`/news/${news.id}`}>
                    <span className="inline-flex w-4/5 font-semibold overflow-hidden">{news.headline}</span>
                  </Link>
                  <span className="font-normal">{(new Date(news.published_at)).toLocaleString("en-GB")}</span>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      }
      </Panel>
    );
}