import { useEffect, useState } from 'react';
import { getList } from "../../common/BackendProvider.jsx";
import {toast, ToastContainer} from "react-toastify";
import { format } from "d3";
import Table from '../../layout/Table.jsx';
import { SearchBar } from './SearchBar.jsx';

export const Companies = () => {
    const[companies, setCompanies] = useState([])
    const[loading, setLoading] = useState(true)
    const[filter, setFilter] = useState({"hasprice": 0, "sector": 0, "industry": 0, "region": 0, "size": 0})

    const loadCompanies = () => {
        setLoading(true)
        getList("companies", {filter: filter, pagination:{page:1, perPage:100}}).then((response) => {
            setCompanies(response.data.companies)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading companies", {})
                setLoading(false)
            }
        )
    }

    useEffect(() => {
        loadCompanies()
    },[filter])

    const handleSectorChange = (sector) => {
        setFilter({...filter, sector: sector})
    }

    const handleIndustryChange = (industry) => {
        setFilter({...filter, industry: industry})
    }

    const handleRegionChange = (region) => {
        setFilter({...filter, region: region})
    }

    const handleSizeChange = (size) => {
        setFilter({...filter, size: size})
    }

    return (
        <div className="p-8 border-l">
            <div className="text-3xl font-bold mb-8">Companies</div>
            <div className="bg-white border rounded-lg">
                <div className="p-4">
                <SearchBar 
                    setSector={handleSectorChange}
                    setIndustry={handleIndustryChange}
                    setRegion={handleRegionChange}
                    setSize={handleSizeChange}
                />
                </div>
                <div className="grid">
                <div className="px-4">
                <Table
                    className="w-full"
                    columns={["Name", "Symbol", "Last Price","ESG Score", "Daily High", "Daily Low", "Volume"]}
                    keys={[
                        {name: 'name', type: 'text', format: (d) => (d), class: "text-left", link: "/company/{symbol}"},
                        {name: 'symbol', type: 'text', format: (d) => (d), class: "text-center", link: "/company/{symbol}"},
                        {name: 'last_price', type: 'text', format: (d) => format(",.2f")(d), class: "text-right", link: "/company/{symbol}"},
                        {name: 'esg_score', type: 'text', format: (d) => format(",.0f")(d), class: "text-right", link: "/company/{symbol}"},
                        {name: 'high_24h', type: 'text', format: (d) => format(",.2f")(d), class: "text-right", link: "/company/{symbol}"},
                        {name: 'low_24h', type: 'text', format: (d) => format(",.2f")(d), class: "text-right", link: "/company/{symbol}"},
                        {name: 'volume_24h', type: 'text', format: (d) => format(",.0f")(d), class: "text-right", link: "/company/{symbol}"}

                    ]}
                    data={companies}
                    readOnly={true}
                />
                </div>
          </div>
        </div>
    </div>
    )
}